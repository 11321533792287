export default {
  props: {
    modelValue: {},
    autoTranslate: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Array,
      default: [],
    },
    nullOption: {
      type: [Object, Boolean],
      default: true,
    },
  },

  data: function () {
    return {};
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    inputAttrs() {
      let attrs = { ...this.$attrs };
      return attrs;
    },
    hasNullOption() {
      return this.nullOption !== false;
    },
    hasSpecificNullOption() {
      return typeof this.nullOption === "object" && this.nullOption !== null;
    },
    finalNullOption() {
      let target = {},
        disabled;

      if (this.hasSpecificNullOption) {
        target = this.nullOption;
      }

      if (target.disabled) {
        disabled = true;
      } else {
        disabled = target.disabled;
      }

      return {
        value: target.value || null,
        label: target.label || "core.selectPlaceholder",
        disabled,
      };
    },
  },
  methods: {
    getLabelString(label) {
      return this.autoTranslate ? this.safeTranslate(label) : label;
    },
  },
};
