<template>
  <select v-model="value" v-bind="inputAttrs">
    <option
      v-if="hasNullOption"
      :disabled="finalNullOption.disabled"
      :value="finalNullOption.value"
      :selected="finalNullOption.value === modelValue">
      {{ getLabelString(finalNullOption.label) }}
    </option>
    <option
      v-for="option in list"
      :value="option.value"
      :selected="option.value === modelValue">
      {{ getLabelString(option.label) }}
    </option>
  </select>
  <icon
    v-if="showChevron === true || showChevron === 'true'"
    icon="chevron-down"
    style="pointer-events: none;"
    class="field__icon field__icon--end "
    theme="gray-4" />
</template>

<script>
import SelectInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/selectInput.js";

export default {
  mixins: [SelectInputMixin],
  props: {
    showChevron: {
      type: [Boolean, String],
      default: true,
    },
  },

  data: function () {
    return {};
  },

  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
